import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { WhatsAppOnboarding } from '../scenes/WhatsAppOnboarding';
import { Accounts } from '../scenes/Accounts';
import { Users } from '../scenes/Users';
import { PartnerKeys } from '../scenes/PartnerKeys';
import { Home } from '../scenes/HomePage';
import { ServiceProviders } from '../scenes/ServiceProvider';

import { Me } from '../types';
import { UsagePage } from '../scenes/Usage';
import { EmbeddedClient } from '../scenes/EmbeddedClient';
import { Audits } from '../scenes/Audits';
import { Credentials } from '../scenes/Credentials';
import { MigrationStatus } from '../scenes/MigrationStatus';
import { Migration } from '../scenes/Migration';
//import { PartnerApiUsage } from './scenes/PartnerApiUsage';
import { PhoneNumbers } from '../scenes/PhoneNumbers';
import { PartnerContent } from '../scenes/PartnerContent';
import { Callback } from '../scenes/Callback';
import DashboardLayout from '../components/DashboardLayout';
import Private from "./Private"
import Public from "./Public"

type Props = {
  me: null | Me;
};

function Routers({ me }: Props): JSX.Element {
  const isLoggedIn = !!me;
  const email = me?.email || null;
  const isAdmin = me?.isAdmin || null;
  const partnerId = me?.partnerId || null;
  const userOnboardingStatus = me?.status || null;

  return (
    <Router>
      <Routes>
        

        {/* public (without effect) */}
          <Route
            path="/wa-client"
            element={<EmbeddedClient userOnboardingStatus={userOnboardingStatus} email={email} />}
          />
          <Route path="/migrate" element={<Migration />} />
          <Route path="/callback" element={<Callback />} />

        {/* private */}
        <Route element={ <Private isLoggedIn={isLoggedIn} /> }>
            <Route element={
                <DashboardLayout isAdmin={isAdmin} email={email} partnerId={partnerId} />
            }>
              <Route
                path="/connect"
                element={
                  
                    <WhatsAppOnboarding
                      userOnboardingStatus={userOnboardingStatus}
                      isAdmin={isAdmin}
                      partnerId={partnerId}
                    />

                }
              />
              <Route path="/accounts" element={
                
                  <Accounts isAdmin={isAdmin} partnerId={partnerId} />

              } />
              <Route path="/phoneNumbers" element={
                
                  <PhoneNumbers isAdmin={isAdmin} partnerId={partnerId} />

              } >
                <Route path=":wabaId" element={
                  
                    <PhoneNumbers isAdmin={isAdmin} partnerId={partnerId} />

                } />
              </Route>
              {(partnerId || isAdmin) && (
                <>
                  <Route path="/users" element={
                    
                      <Users isAdmin={isAdmin} />

                  } />
                  <Route path="/migrations" element={
                    
                      <MigrationStatus isAdmin={isAdmin} email={email} />

                  } />
                </>
              )}
              {partnerId && (
                <>
                  <Route path="/partner-content" element={
                    
                      <PartnerContent />

                  } />
                  <Route path="/keys" element={
                    
                      <PartnerKeys />

                  } />
                  <Route path="/credentials" element={
                    
                      <Credentials />

                  } />
                </>
              )}

              {isAdmin && (
                <>
                  <Route path="/audits" element={
                    
                      <Audits />

                  } />
                  <Route
                    path="/service-provider"
                    element={
                      
                        <ServiceProviders isAdmin={isAdmin} partnerId={partnerId} />

                    }
                  />
                </>
              )}

              <Route path="/usage" element={
                
                  <UsagePage />

              } />
            </Route>
        </Route>

        {/* public (with effect) */}
        <Route element={ <Public isLoggedIn={isLoggedIn} /> } >
          <Route path="/" element={
              <Home email={email} />
          } />
          <Route path="*" element={<Navigate to='/'/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default Routers;
