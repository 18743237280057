import React from 'react';

import { ColumnsType } from 'antd/lib/table';
import { Table, notification } from 'antd';

import * as api from '../../api';
import { PhoneNumber, NewPhonesListType } from '../../types';
import { formatDatetime } from '../../utils/datetime';
import { RemoveButton, ReloadButton } from '../../components';
import { countryFlags } from "."
import ReactCountryFlag from "react-country-flag"

import PhoneCert from "./PhoneCert"
import PhoneProvisionServer from "./PhoneProvisionServer"

type PhoneNumbersTableProps = {
  phoneNumbers: NewPhonesListType;
  isAdmin: boolean | null;
  partnerId: number | null;
  page: number;
  size: number;
  total: number;
  fetchData(): void;
  onPaginationChanged(page: number, pageSize?: number | undefined): void;
};


const notificationReloadSuccess = (wabaName: string | undefined) => {
  notification['success']({
    message: `Update WABA -${wabaName}- Successfully`,
  });
};

const extractCountryCode = (phoneNumber : string) : string => {
  return phoneNumber.split(" ")[0].replace("+", "")
}
const getCountryFlag = (phoneCode : string) : string => countryFlags[phoneCode];

const PhoneNumbersTable = ({
  phoneNumbers,
  page,
  size,
  total,
  onPaginationChanged,
  fetchData,
  isAdmin,
}: PhoneNumbersTableProps) => {
  const handleQualityRating = (qualityRating: string | null): JSX.Element => {
    let result: string | null = qualityRating;
    let color = 'white';
    if (!qualityRating) return <p>NULL</p>;
    qualityRating = qualityRating.toLocaleLowerCase();
    if (qualityRating == 'green') {
      result = 'High';
      color = 'green';
    } else if (qualityRating == 'yellow') {
      result = 'Medium';
      color = 'yellow';
    } else if (qualityRating == 'red') {
      result = 'Low';
      color = 'red';
    } else {
      return <p><strong>Quality rating:</strong> {result}</p>;
    }
    return (
      <div>
        <strong>Quality rating:</strong>&nbsp;
        <span className={`dot dot-${color}`} />
        <a
          className="ml-5"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/business/help/896873687365001"
        >
          {` ${result}`}
        </a>
      </div>
    );
  };

  const columns: ColumnsType<NewPhonesListType[number]> = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render(createdAt) {
        return <>{formatDatetime(createdAt)}</>;
      },
    },
    {
      title: 'WABA',
      key: 'waba',
      align: 'center',
      onCell: (record : NewPhonesListType[number]) => {
        return {
          rowSpan : record.rowSpan
        }
      },
      render: function WabaName({ account: { name, wabaId } }: NewPhonesListType[number]) {
        return (
            <>
              <strong>{name}</strong>
              <br />
              {wabaId}
              <br />
            </>
          )
      }
    },
    {
      title: 'Phone Number',
      key: 'value',
      align: 'center', 
      render: function PhoneNumber(phone: NewPhonesListType[number]) {
        const code = getCountryFlag(extractCountryCode(phone.value))
        return (
          <>
            <strong>{phone.verifiedName}</strong>
            <br />
            <ReactCountryFlag 
              countryCode={code}
              svg
            /> {" "}
            {phone.value}
            <br />
          </>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: function PhoneStatus(phone: NewPhonesListType[number]) {
        return (
          <div style={{ textAlign: 'left' }}>
            <strong>Status:</strong> {phone.status} <br />
            <strong>Name status:</strong> {phone.nameStatus}
            <br />
            {phone.limit && (
              <>
                <strong>Limit:</strong> {phone.limit}
                <br />
              </>
            )}
            {handleQualityRating(phone.qualityRating)}
          </div>
        );
      },
    },
    {
      title: 'Phone Cert',
      key: 'phoneCert',
      align: 'center',
      render: function phoneCert(value: NewPhonesListType[number]) {
        return <PhoneCert value={value} />
      },
    },
    {
      title: 'Provision Server',
      key: 'provisionServer',
      align: 'center',
      render(value: NewPhonesListType[number]) {
        return <PhoneProvisionServer value={value} isAdmin={isAdmin} />
      },
    },
    {
      title: '',
      align: 'center',
      key: 'tools',
      render: function Tools(record: NewPhonesListType[number]) {
        const handleRemove = async () => {
          try {
            const isRemove = await api.phoneNumber.remove(record.id);
            if (isRemove) {
              await fetchData();
            }
          } catch (error) {
            throw error;
          }
        };
        const handleReload = async () => {
          try {
            const isReload = record.account?.wabaId && (await api.account.reload(record.account?.wabaId));
            if (isReload) {
              await fetchData();
              notificationReloadSuccess(record.account?.name);
            }
          } catch (error) {
            throw error;
          }
        };
        return (
          <div className="flex flex-jc-c flex-ai-c ">
            {isAdmin ? (
              <>
                <div className="mr-10">
                  <ReloadButton handleReload={handleReload} uniqueValue={record.account?.name} />
                </div>
                <RemoveButton handleRemove={handleRemove} uniqueValue={record.verifiedName} />
              </>
            ) : (
              <ReloadButton handleReload={handleReload} uniqueValue={record.account?.name} />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={phoneNumbers.map((phoneNumber) => {
          return { ...phoneNumber, key: phoneNumber.id };
        })}
        bordered
        scroll={{ x: true }}
        pagination={{
          defaultCurrent: 1,
          current: page,
          total: total,
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSize: size,
          onChange: (page: number, pageSize?: number | undefined) => onPaginationChanged(page, pageSize),
        }}
      />
    </>
  );
};

export default PhoneNumbersTable;
